import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setIsActive, setDSActive, setSelectedMode, setMobileDSMode, setCleanUpAll,
          clearInputFileForEncrypt, clearCertificateInputFile, clearInputFileForDecrypt, clearDecryptedData, clearEncryptedData,
          clearInputFileForDS, clearDSData, clearInputTextForDS, clearDSInputTextDescription, clearDSDataBase64,
          clearFileList, clearFileListWithDS, clearPrevisionDsText, clearDecryptText, clearInputTextForDecryptUintArr, clearInputTextForDecrypt,
          clearInputTextForEncryptUintArr, clearInputTextForEncrypt, clearEncryptDataBase64, clearPrevisionDsFile, clearInputDescription, clearFilesForEncrypt,
          clearEncryptedFiles, clearFullPhoneNumber, clearPhoneNumber, clearOwnerOrganization, clearBaseFileForUpgrade, clearFileWithDSForUpgrade,
          clearModifiedDSData, clearUpgradedDSInBase64, clearTextDSDataBase64, clearBaseTextForUpgrade, clearInputTextDSForVerify, clearVerifyInputText,
          clearVerifyResultText, clearTextSignedData, clearVerifyResultData, clearFileSignedData, clearKeyContainer, setActivationPeriod,
          setShowUpgradeCard, clearKeyContainerPass, clearCertificateInfo, clearCertificateInfoKeyAgreement, clearVerifyInputFile, clearVerifyDSInputFile,
          setTokenInfo, clearVPNToken, clearVPNTokenData, clearProfileFields, clearResultKey, clearSecret, clearErrorRespons, setAllFieldsFiled, clearVtcoKeysInfo,
          clearVtcoSelectedKey, setVtcoTokenBody, setIsVtco, setVtcoPin, clearVtcoUserName, setXadesType,
          setAsicContainerForm, setExpireTime, setCadesType } from '../../actions/localStates'
import { bindActionCreators } from 'redux';
import i18next from 'i18next'

class CountDownTimer extends Component {
  constructor() {
    super();
    this.state = {
      time: {},
      seconds: 5400,
      settedTimeValue: false
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.setTime = this.setTime.bind(this);
    this.setTimeValue = this.setTimeValue.bind(this);
  }

  secondsToTime(secs){
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": (hours < 1 ? "00" : "0" + hours),
      "m": (minutes < 10 ? "0" + minutes : minutes),
      "s": (seconds < 10 ? "0" + seconds : seconds)
    };
    return obj;
  }

  componentDidMount() {
    let timeLeftVar = this.secondsToTime(this.props.pkLocalState.settedTime * 60);
    this.setState({ time: timeLeftVar });
  }

  setTimeValue() {
    this.setState({settedTimeValue: false})
    clearInterval(this.timer);
    this.timer = 0;
  }

  setTime() {
    let timeLeftValue
    this.setState({seconds: this.props.pkLocalState.settedTime * 60})
    this.setState({settedTimeValue: true})
    timeLeftValue = this.secondsToTime(this.props.pkLocalState.settedTime * 60)
    this.setState({ time: timeLeftValue }, () => {this.startTimer()});
  }

  startTimer() {
    if (this.timer === 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    if (this.props.base.isVtco) {
      if ((Date.now() / 1000) > this.props.base.expireTime) {
        this.props.actions.setExpireTime()
        clearInterval(this.timer);
        this.props.actions.setDSActive(false)
        this.props.actions.setIsActive(false)
        this.props.actions.setMobileDSMode(false)
        this.props.actions.setSelectedMode("0")
        this.props.actions.setCleanUpAll(true)
        alert(i18next.t("tokenExpired"))
        this.props.actions.clearInputFileForEncrypt()
        this.props.actions.clearCertificateInputFile()
        this.props.actions.clearInputFileForDecrypt()
        this.props.actions.clearDecryptedData()
        this.props.actions.clearEncryptedData()
        this.props.actions.clearInputFileForDS()
        this.props.actions.clearDSData()
        this.props.actions.clearInputTextForDS()
        this.props.actions.clearDSInputTextDescription()
        this.props.actions.clearDSDataBase64()
        this.props.actions.clearFileList()
        this.props.actions.clearFileListWithDS()
        this.props.actions.clearPrevisionDsText()
        this.props.actions.clearDecryptText()
        this.props.actions.clearInputTextForDecryptUintArr()
        this.props.actions.clearInputTextForDecrypt()
        this.props.actions.clearInputTextForEncryptUintArr()
        this.props.actions.clearInputTextForEncrypt()
        this.props.actions.clearEncryptDataBase64()
        this.props.actions.clearPrevisionDsFile()
        this.props.actions.clearInputDescription()
        this.props.actions.clearFilesForEncrypt()
        this.props.actions.clearEncryptedFiles()
        this.props.actions.clearFullPhoneNumber()
        this.props.actions.clearPhoneNumber()
        this.props.actions.clearOwnerOrganization()
        this.props.actions.clearBaseFileForUpgrade()
        this.props.actions.clearFileWithDSForUpgrade()
        this.props.actions.clearModifiedDSData()
        this.props.actions.clearUpgradedDSInBase64()
        this.props.actions.clearTextDSDataBase64()
        this.props.actions.clearBaseTextForUpgrade()
        this.props.actions.clearInputTextDSForVerify()
        this.props.actions.clearVerifyInputText()
        this.props.actions.clearVerifyResultText()
        this.props.actions.clearTextSignedData()
        this.props.actions.clearVerifyResultData()
        this.props.actions.clearFileSignedData()
        this.props.actions.clearKeyContainer()
        this.props.actions.setActivationPeriod(15)
        this.props.actions.setShowUpgradeCard(false)
        this.props.actions.clearKeyContainerPass()
        this.props.actions.clearCertificateInfo()
        this.props.actions.clearCertificateInfoKeyAgreement()
        this.props.actions.clearVPNToken()
        this.props.actions.clearVPNTokenData()
        this.props.actions.clearProfileFields()
        this.props.actions.clearResultKey()
        this.props.actions.clearSecret()
        this.props.actions.clearErrorRespons()
        this.props.actions.setAllFieldsFiled(true)
        this.props.actions.setTokenInfo(null)
        this.props.actions.clearVtcoKeysInfo()
        this.props.actions.clearVtcoSelectedKey()
        this.props.actions.setVtcoTokenBody("")
        this.props.actions.setIsVtco(false)
        this.props.actions.setVtcoPin("")
        this.props.actions.clearVtcoUserName()
        this.props.actions.setXadesType("NONE")
        this.props.actions.setAsicContainerForm("NONE")
        this.props.actions.setCadesType("CADES_X_LONG")
      }
    }
    
    if (seconds === 0) { 
      clearInterval(this.timer);
      this.props.actions.setDSActive(false)
      this.props.actions.setIsActive(false)
      this.props.actions.setMobileDSMode(false)
      this.props.actions.setSelectedMode(0)
      this.props.actions.setCleanUpAll(true)
      alert(i18next.t("timeOutTitle"))
      this.props.actions.clearInputFileForEncrypt()
      this.props.actions.clearCertificateInputFile()
      this.props.actions.clearInputFileForDecrypt()
      this.props.actions.clearDecryptedData()
      this.props.actions.clearEncryptedData()
      this.props.actions.clearInputFileForDS()
      this.props.actions.clearDSData()
      this.props.actions.clearInputTextForDS()
      this.props.actions.clearDSInputTextDescription()
      this.props.actions.clearDSDataBase64()
      this.props.actions.clearFileList()
      this.props.actions.clearFileListWithDS()
      this.props.actions.clearPrevisionDsText()
      this.props.actions.clearDecryptText()
      this.props.actions.clearInputTextForDecryptUintArr()
      this.props.actions.clearInputTextForDecrypt()
      this.props.actions.clearInputTextForEncryptUintArr()
      this.props.actions.clearInputTextForEncrypt()
      this.props.actions.clearEncryptDataBase64()
      this.props.actions.clearPrevisionDsFile()
      this.props.actions.clearInputDescription()
      this.props.actions.clearFilesForEncrypt()
      this.props.actions.clearEncryptedFiles()
      this.props.actions.clearFullPhoneNumber()
      this.props.actions.clearPhoneNumber()
      this.props.actions.clearOwnerOrganization()
      this.props.actions.clearBaseFileForUpgrade()
      this.props.actions.clearFileWithDSForUpgrade()
      this.props.actions.clearModifiedDSData()
      this.props.actions.clearUpgradedDSInBase64()
      this.props.actions.clearTextDSDataBase64()
      this.props.actions.clearBaseTextForUpgrade()
      this.props.actions.clearInputTextDSForVerify()
      this.props.actions.clearVerifyInputText()
      this.props.actions.clearVerifyResultText()
      this.props.actions.clearTextSignedData()
      this.props.actions.clearVerifyResultData()
      this.props.actions.clearFileSignedData()
      this.props.actions.clearKeyContainer()
      this.props.actions.setActivationPeriod(15)
      this.props.actions.setShowUpgradeCard(false)
      this.props.actions.clearKeyContainerPass()
      this.props.actions.clearCertificateInfo()
      this.props.actions.clearCertificateInfoKeyAgreement()
      this.props.actions.setTokenInfo(null)
      this.props.actions.clearCertificateInfoKeyAgreement()
      this.props.actions.clearVPNToken()
      this.props.actions.clearVPNTokenData()
      this.props.actions.clearProfileFields()
      this.props.actions.clearResultKey()
      this.props.actions.clearSecret()
      this.props.actions.clearErrorRespons()
      this.props.actions.setAllFieldsFiled(true)
      this.props.actions.setTokenInfo(null)
      this.props.actions.clearVtcoKeysInfo()
      this.props.actions.clearVtcoSelectedKey()
      this.props.actions.setVtcoTokenBody("")
      this.props.actions.setIsVtco(false)
      this.props.actions.setVtcoPin("")
      this.props.actions.clearVtcoUserName()
      this.props.actions.setXadesType("NONE")
      this.props.actions.setAsicContainerForm("NONE")
      this.props.actions.setCadesType("CADES_X_LONG")
    }
  }

  render() {
    return(
      <div>
        {
          this.props.pkLocalState.isActive
          ? <div>
            {
              this.props.connectionStatus.status ? null : this.props.actions.setDSActive(false)
            }
            {
              this.props.connectionStatus.status ? null : this.props.actions.setIsActive(false)
            }
            {this.state.settedTimeValue ? null : this.setTime()}
            {this.state.time.h}:{this.state.time.m}:{this.state.time.s}
          </div> : this.state.settedTimeValue ? this.setTimeValue() : null
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
    return {
        connectionStatus: state.connectionStatusReucer,
        locales: state.i18n,
        pkLocalState: state.privateKeyReducer,
        base: state.base
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
      setIsActive,
      setDSActive,
      setSelectedMode,
      setMobileDSMode,
      setCleanUpAll,
      clearInputFileForEncrypt, clearCertificateInputFile, clearInputFileForDecrypt, clearDecryptedData, clearEncryptedData,
      clearInputFileForDS, clearDSData, clearInputTextForDS, clearDSInputTextDescription, clearDSDataBase64,
      clearFileList, clearFileListWithDS, clearPrevisionDsText, clearDecryptText, clearInputTextForDecryptUintArr, clearInputTextForDecrypt,
      clearInputTextForEncryptUintArr, clearInputTextForEncrypt, clearEncryptDataBase64, clearPrevisionDsFile, clearInputDescription, clearFilesForEncrypt,
      clearEncryptedFiles, clearFullPhoneNumber, clearPhoneNumber, clearOwnerOrganization, clearBaseFileForUpgrade, clearFileWithDSForUpgrade,
      clearModifiedDSData, clearUpgradedDSInBase64, clearTextDSDataBase64, clearBaseTextForUpgrade, clearInputTextDSForVerify, clearVerifyInputText,
      clearVerifyResultText, clearTextSignedData, clearVerifyResultData, clearFileSignedData, clearKeyContainer, setActivationPeriod,
      setShowUpgradeCard, clearKeyContainerPass, clearCertificateInfo, clearCertificateInfoKeyAgreement, clearVerifyInputFile, clearVerifyDSInputFile,
      setTokenInfo, clearVPNToken, clearVPNTokenData, clearProfileFields, clearResultKey, clearSecret, clearErrorRespons, setAllFieldsFiled, setTokenInfo, clearVtcoKeysInfo,
      clearVtcoSelectedKey, setVtcoTokenBody, setIsVtco, setVtcoPin, clearVtcoUserName, setXadesType, setAsicContainerForm,
      setExpireTime, setCadesType
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CountDownTimer);