
const dafaultState = {
    expandCreateDSType: true,
    expandCreateDSTSVerify: false,
    expandCreateDSContainerType: false,
    signatureType: "detached",
    embedSignatureTs: "true",
    embedSignatureTs: "false",
    embedSignatureTsChecked: true,
    embedDataTs: "true",
    embedDataTsChecked: true,
    embedCertificateType: "signerAndCaCert",
    signatureTsVerifyOption: "ignore",
    dataTsVerifyOption: "ignore",
    embedCertificateType: "signerCert",
    signatureTsVerifyOption: "verifyIfPresent",
    dataTsVerifyOption: "verifyIfPresent",
    tsAdditionalVerifying: false,
    dataToSignQualifier: "notSignedBefore",
    duplicateSign: false,
    caId: null,
    cadesType: "CAdESXLong",
    cadesType: "CADES_X_LONG",
    expandCreateDSCadesType: false,
    showUpgradeCard: false,
    generateQR: false,
    cleanUpForm: false,
    xadesType: "NONE",
    asicForm: "NONE"
}

const createDSOptionsReducer = (state = dafaultState, { type, payload }) => {

    switch (type) {
    	case 'EXPAND_CREATE_DS_TYPE': {
            return {
                ...state,
                expandCreateDSType: payload
            }
        }

        case 'SHOW_UPGARADE_CARD': {
            return {
                ...state,
                showUpgradeCard: payload
            }
        }

        case 'EXPAND_CREATE_DS_CONTAINER_TYPE': {
            return {
                ...state,
                expandCreateDSContainerType: payload
            }
        }

        case 'EXPAND_CREATE_DS_TS_VERIFY': {
            return {
                ...state,
                expandCreateDSTSVerify: payload
            }
        }

        case 'EXPAND_CREATE_DS_CADES_TYPE': {
            return {
                ...state,
                expandCreateDSCadesType: payload
            }
        }

        case 'SET_DS_SIGNATURE_TYPE': {
            return {
                ...state,
                signatureType: payload
            }
        }

        case 'SET_GENERATE_QR_DS': {
            return {
                ...state,
                generateQR: payload
            }
        }

        case 'SET_CLEAN_UP_FORM': {
            return {
                ...state,
                cleanUpForm: payload
            }
        }

        case 'SET_ADD_DS_TO_EXIST': {
            return {
                ...state,
                dataToSignQualifier: payload
            }
        }

        case 'SET_DUPLICATE_SIGN': {
            return {
                ...state,
                duplicateSign: payload
            }
        }

        case 'ADD_DATA_TS': {
            return {
                ...state,
                embedDataTsChecked: payload.checked,
                embedDataTs: payload.value
            }
        }

        case 'ADD_DS_TS': {
            return {
                ...state,
                embedSignatureTsChecked: payload.checked,
                embedSignatureTs: payload.value
            }
        }

        case 'SET_CA_ID': {
            return {
                ...state,
                caId: payload
            }
        }

        case 'DS_TS_CHECK': {
            return {
                ...state,
                embedCertificateType: payload
            }
        }

        case 'SET_CADES_TYPE': {
            return {
                ...state,
                cadesType: payload
            }
        }

        case 'SET_XADES_TYPE': {
            return {
                ...state,
                xadesType: payload
            }
        }

        case 'SET_ASIC_CONTAINER_FORM': {
            return {
                ...state,
                asicForm: payload
            }
        }

        default:
            return state;
    }

}

export default createDSOptionsReducer


